import './css/business.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useRef } from 'react';
import AOS from 'aos';
import { useSelector, useDispatch } from 'react-redux'

function Business() {
    const [btn, setBtn] = useState(0)
    let business = useSelector((state) => { return state.SelectBusiness.value });

    useEffect(() => {
        AOS.init();
    })

    useEffect(() => {
        if (business === 1) onContent1Click()
        else if (business === 2) onContent2Click()
        else if (business === 3) onContent3Click()
        else if (business === 4) onContent4Click()
        else if (business === 0) scrollToTop()
    }, [business])

    const targetRef1 = useRef(null);
    const targetRef2 = useRef(null);
    const targetRef3 = useRef(null);
    const targetRef4 = useRef(null);

    const onContent1Click = () => {
        targetRef1.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const onContent2Click = () => {
        targetRef2.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const onContent3Click = () => {
        targetRef3.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const onContent4Click = () => {
        targetRef4.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className='business'>
            <div className='business-top'>
                <div className='business-top-img'></div>
            </div>
            <div className='business-section1'>
                <div className='business-section1-1' data-aos="fade-down" data-aos-duration="1500" data-aos-once="true">해피브릿지 사업 영역</div>
                <div className='business-section1-2' data-aos="fade-down" data-aos-duration="1500" data-aos-delay="100" data-aos-once="true">핀테크, 웹메일, 법인용 공인인증서 발급 등
                    다양한 분야의 어려움을 가진 사람을 연결시키고자 합니다.</div>
            </div>
            <div className='business-section3'>
                <div className='about-section1-1' ref={targetRef4}>
                    <div className={'about-section1-1-2 active'} onClick={() => onContent4Click()}>텔레그램 미니앱</div>
                    <div className={'about-section1-1-2'} onClick={() => onContent1Click()}>전자금융업 솔루션</div>
                    <div className={'about-section1-1-2'} onClick={() => onContent2Click()}>웹메일 솔루션</div>
                    <div className={'about-section1-1-2'} onClick={() => onContent3Click()}>법인용 공인인증서 발급</div>
                </div>
                <div className='about-section1-2'></div>
                <div className='business-section3-1'>
                    <div className='business-section3-1-1'>
                        <div className='business-section3-1-1-1'>-텔레그램 미니앱</div>
                        <div className='business-section3-1-1-2'>양자암호통신기반 지갑서비스(q-Wallet)</div>
                        <div className='business-section3-1-1-3'>
                            해피브릿지는 KT와 컨소시엄을 구성해 NIA(한국지능정보사회진흥원) 양자암호통신 시범사업을 수행하였습니다.<br /><br />

                            q-Wallet은 QKD 장비를 이용하여 양자암호화 보안 통신 구간에서 사용되는 거래소의 코인지갑으로, 코인지갑에서
                            사용되는 암호화키를 QKD 장비에서 내려받아 적용됩니다.<br /><br />

                            QKD 기반의 지갑주소 생성 모듈을 활용한 코인 지갑 관리 앱을 개발하였으며, 해당 모듈을 사용하는 ERC20
                            플랫폼에 코인을 상장 테스트를 완료하였습니다.</div>
                    </div>
                    <div className='business-section3-1-2' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                        <img src='/img/Q-Wallet__1_.jpg' />
                    </div>
                </div>
                <div className='business-section3-2'>
                    <div className='business-section3-1-2' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                        <img src='/img/telegram_miniapp.jpg' />
                    </div>
                    <div className='business-section3-1-1'>
                        <div className='business-section3-1-1-1'>-텔레그램 미니앱</div>
                        <div className='business-section3-1-1-2'>텔레그램 미니앱 게임(q-Game)</div>
                        <div className='business-section3-1-1-3'>
                            q-Game은 HappyBridge에서 단독으로 개발한 보상형 게임으로, 텔레그램 미니앱을 통해 간편하게 즐길 수 있는 탭2언(Tap2Earn) 게임이며,
                            간단한 조작으로 사용자에게 새로운 게임 경험과 실질적인 보상을 제공합니다.<br /><br />

                            q-Game은 간단한 터치 조작만으로 다양한 미션을 완료하고 보상을 획득할 수 있으며, 사용자는 게임을 즐기면서 포인트를 모으고, 이를 다양한 방식으로 활용할 수 있습니다.<br /><br />

                            q-Game은 게임 내에서 얻은 포인트와 아이템을 통해 실제 가치로 전환할 수 있는 기회를 제공합니다. 이를 통해 게임의 재미와 경제적 가치를 동시에 누릴 수 있습니다.<br /><br />

                            텔레그램 미니앱으로 구현된 q-Game은 별도의 앱 설치 없이 빠르게 접속할 수 있으며, 직관적인 인터페이스로 누구나 쉽게 이용할 수 있습니다.

                        </div>
                    </div>
                </div>
            </div>
            <div className='business-section3'>
                <div className='about-section1-1' ref={targetRef1}>
                    <div className={'about-section1-1-2'} onClick={() => onContent4Click()}>텔레그램 미니앱</div>
                    <div className={'about-section1-1-2 active'} onClick={() => onContent1Click()}>전자금융업 솔루션</div>
                    <div className={'about-section1-1-2'} onClick={() => onContent2Click()}>웹메일 솔루션</div>
                    <div className={'about-section1-1-2'} onClick={() => onContent3Click()}>법인용 공인인증서 발급</div>
                </div>
                <div className='about-section1-2'></div>
                <div className='business-section3-1'>
                    <div className='business-section3-1-1'>
                        <div className='business-section3-1-1-1'>-전자금융업 솔루션</div>
                        <div className='business-section3-1-1-2'>PG 솔루션</div>
                        <div className='business-section3-1-1-3'>
                            해피브릿지의 PG솔루션은 2021년 국내 최초 금감원 사용보고를 승인받은 ‘클라우드 전용 솔루션’입니다.<br /><br />

                            2023년 KT Cloud와 파트너 협약을 체결하였고, F-Cloud 기반 모든 시스템과 인프라를 통합 지원하여
                            최소한의 인력으로 24시간 중단없는 금융서비스를 제공하고 있습니다.<br /><br />

                            기존의 시설 및 전문인력 등 PG 솔루션 운영에 수많은 고민을 PayBlue(클라우드 솔루션)를 통해 지원하겠습니다.<br /><br />

                            자사의 PG설립 경험을 바탕으로 PG 사업을 준비하는 후발주자에게는 솔루션뿐만 아니라
                            다양한 컨설팅 또한 제공하고 있습니다.</div>
                    </div>
                    <div className='business-section3-1-2' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                        <img src='/img/KT_CLOUD2.jpg' />
                    </div>
                </div>
            </div>
            <div className='business-section3'>
                <div className='about-section1-1' ref={targetRef2}>
                    <div className={'about-section1-1-2'} onClick={() => onContent4Click()}>텔레그램 미니앱</div>
                    <div className={'about-section1-1-2'} onClick={() => onContent1Click()}>전자금융업 솔루션</div>
                    <div className={'about-section1-1-2 active'} onClick={() => onContent2Click()}>웹메일 솔루션</div>
                    <div className={'about-section1-1-2'} onClick={() => onContent3Click()}>법인용 공인인증서 발급</div>
                </div>
                <div className='about-section1-2'></div>
                <div className='business-section3-1'>
                    <div className='business-section3-1-1'>
                        <div className='business-section3-1-1-1'>-웹메일 솔루션</div>
                        <div className='business-section3-1-1-2'>Smart Mailer</div>
                        <div className='business-section3-1-1-3'>
                            스마트메일러는 대법원, LF 등 대용량 고성능 메일 사용자에게 지원하는 메일 발송 서비스입니다.<br /><br />

                            국정원 웹메일 보안 심사를 통과 및 통신구간 SSL 암호화/ PKI 기반 인증서를 적용하여,
                            국내 최고의 보안 기능을 제공하고 있습니다.<br /><br />

                            성능과 보안성을 기반으로 통합 시스템을 위한 다양한 연계 기능을 제공하고 있습니다.</div>
                    </div>
                    <div className='business-section3-1-2' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                        <img src='/img/Smart_Mailer__1.jpg' />
                    </div>
                </div>
                <div className='business-section3-2'>
                    <div className='business-section3-1-2' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                        <img src='/img/Smart_List_br.jpg' />
                    </div>
                    <div className='business-section3-1-1'>
                        <div className='business-section3-1-1-1'>-웹메일 솔루션</div>
                        <div className='business-section3-1-1-2'>Smart List</div>
                        <div className='business-section3-1-1-3'>
                            스마트리스트는 국가표준 암호화 알고리즘(ARIA)를 활용한 보안 메일 기능을 탑재하고 있으며,
                            서울시 외국인 종합지원시스템 사업에 구축 지원을 한 발송 솔루션입니다.<br /><br />

                            현재, 대법원 전자소송 문서를 발송을 지원하여 전자소송 관련 메일이 정확히 발송될 수 있도록
                            로그 분석과 정상 작동 기능을 실시간 모니터링하고 있습니다.</div>
                    </div>
                </div>
            </div>
            <div className='business-section3'>
                <div className='about-section1-1' ref={targetRef3}>
                    <div className={'about-section1-1-2'} onClick={() => onContent4Click()}>텔레그램 미니앱</div>
                    <div className={'about-section1-1-2'} onClick={() => onContent1Click()}>전자금융업 솔루션</div>
                    <div className={'about-section1-1-2'} onClick={() => onContent2Click()}>웹메일 솔루션</div>
                    <div className={'about-section1-1-2 active'} onClick={() => onContent3Click()} >법인용 공인인증서 발급</div>
                </div>
                <div className='about-section1-2'></div>
                <div className='business-section3-1'>
                    <div className='business-section3-1-1'>
                        <div className='business-section3-1-1-1'>-법인용 공인인증서 발급</div>
                        <div className='business-section3-1-1-2'>법인용 공인인증서 발급</div>
                        <div className='business-section3-1-1-3'>
                            해피브릿지는 KTNET과 협약을 통해 TradeSign 범용공동인증서를 제공하고 있습니다.<br />
                            은행이나 기관에 방문 및 별도의 서류접수 등 시간 소요 없이 해피브릿지 사무실에서 즉시 발급이 가능합니다.<br /><br />

                            해피브릿지 고객에게는 30% 이상의 할인율을 제공하니, 개별적으로 연락주셔도 안내해 드리겠습니다.</div>
                    </div>
                    <div className='business-section3-1-2' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                        <img src='/img/법인용공인인증서.jpg' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Business;