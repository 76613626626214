import './css/about.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useRef } from 'react';
import AOS from 'aos';
import { useSelector, useDispatch } from 'react-redux'
import { checkHeaderType } from '../store';

function About() {
    const [btn, setBtn] = useState(0)
    const [btn1, setBtn1] = useState(0)
    let about = useSelector((state) => { return state.SelectAbout.value });

    let dispatch = useDispatch()

    useEffect(() => {
        AOS.init();
    })

    useEffect(() => {
        if (about === 1) onContent4Click()
        else if (about === 2) onContent5Click()
        else if (about === 3) onContent6Click()
        else if (about === 4) onContent2Click()
        else if (about === 5) onContent3Click()
        else if (about === 0) scrollToTop()
    }, [about])

    const targetRef1 = useRef(null);
    const targetRef2 = useRef(null);
    const targetRef3 = useRef(null);
    const targetRef4 = useRef(null);
    const targetRef5 = useRef(null);
    const targetRef6 = useRef(null);


    const onContent1Click = () => {
        targetRef1.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        dispatch(checkHeaderType(1))
    }
    const onContent2Click = () => {
        targetRef2.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        dispatch(checkHeaderType(1))
    }
    const onContent3Click = () => {
        targetRef3.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        dispatch(checkHeaderType(1))
    }
    const onContent4Click = () => {
        targetRef4.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        dispatch(checkHeaderType(1))
    }
    const onContent5Click = () => {
        targetRef5.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        dispatch(checkHeaderType(1))
    }
    const onContent6Click = () => {
        targetRef6.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        dispatch(checkHeaderType(1))
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className='about'>
            <div className='about-top'>
                <div className='about-top-img'></div>
                <div className='about-top-1'>
                    <div className='about-top-1-1'>
                        <div className='about-top-1-2' data-aos="fade-down" data-aos-duration="1500" data-aos-once="true">해피브릿지 소개</div>
                        <div className='about-top-1-3' data-aos="fade-down" data-aos-duration="1500" data-aos-delay="100" data-aos-once="true">소외된 고객의 행복을 연결하는 기업</div>
                        <div className='about-top-1-4' data-aos="fade-down" data-aos-duration="1500" data-aos-delay="200" data-aos-once="true">해피브릿지는 텅 빈 마음을 가득 채우기 위해 더 나은 서비스를 만듭니다.<br />
                            의사결정이 어려운 사람, 마음에 부담이 많은 사람.<br />
                            기존 서비스가 채워주지 못했던 공간을 채우며 사람과 사람 사이의 행복을 연결합니다.</div>
                    </div>
                </div>
            </div>
            <div className='about-section1'>
                <div className='about-section1-1' ref={targetRef1}>
                    <div className={'about-section1-1-2 active'} onClick={() => onContent1Click()}>해피브릿지</div>
                    <div className={'about-section1-1-2'} onClick={() => onContent2Click()}>히스토리</div>
                    <div className={'about-section1-1-2'} onClick={() => onContent3Click()}>특허</div>
                </div>
                <div className='about-section1-2'></div>
                <div className='about-section1-3' ref={targetRef4}>
                    <div className={btn1 === 0 ? 'about-section1-3-1 active' : 'about-section1-3-1'} onClick={() => { setBtn1(0); onContent4Click() }}>비전</div>
                    <div className={btn1 === 1 ? 'about-section1-3-1 active' : 'about-section1-3-1'} onClick={() => { setBtn1(1); onContent5Click() }}>핵심가치</div>
                    <div className={btn1 === 2 ? 'about-section1-3-1 active' : 'about-section1-3-1'} onClick={() => { setBtn1(2); onContent6Click() }}>윤리경영</div>
                </div>
                <div className='about-section1-4'>
                    <div className='about-section1-4-1' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">비전</div>
                    <div className='about-section1-4-2'>'행복'</div>
                    <div className='about-section1-4-3'>
                        <div className='about-section1-4-3-1'>소외된 고객의 행복을 연결하는 기업</div>
                        <div className='about-section1-4-3-2'>
                            해피브릿지는 텅 빈 마음을 가득 채우기 위해 더 나은 서비스를 만듭니다.<br />
                            의사결정이 어려운 사람, 마음에 부담이 많은 사람.<br />
                            기존 서비스가 채워주지 못했던 공간을 채우며 사람과 사람 사이의 행복을 연결합니다.<br /><br />
                            또 해피브릿지 고객간의 “행복”을 연결하기 위해 힘쓰고 있습니다.<br />
                            고객지향의 솔루션을 통해 다양한 서비스를 제공하고 있으며,<br />
                            더 나아가 해피브릿지 고객사 임직원의 가정에 행복을 전달하기 위해 노력하겠습니다.
                        </div>
                    </div>
                </div>
                <div className='about-section1-5'></div>
                <div className='about-section1-6' ref={targetRef5}>
                    <div className='about-section1-6-1'>핵심가치</div>
                    <div className='about-section1-6-2'>
                        <div className='about-section1-6-2-1'></div>
                        <div className='about-section1-6-2-2' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                            <div className='about-section1-6-2-3'>
                                <div className='about-section1-6-2-4'>압도적인 경쟁력</div>
                                <div className='about-section1-6-2-5'>해피브릿지는 많은 특허와 KT Colud를 이용하여 압도적인 경쟁력을 만들었습니다.</div>
                            </div>
                        </div>
                    </div>
                    <div className='about-section1-6-2'>
                        <div className='about-section1-6-3-1' ></div>
                        <div className='about-section1-6-2-2' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                            <div className='about-section1-6-2-3'>
                                <div className='about-section1-6-2-4'>고객지향의 솔루션</div>
                                <div className='about-section1-6-2-5'>해피브릿지가 제공하는 KT 금융클라우드를 도입시 최대 초기투자비용 약 5억원 및 운영비용의 60% 절감</div>
                            </div>
                        </div>
                    </div>
                    <div className='about-section1-6-2'>
                        <div className='about-section1-6-4-1' ></div>
                        <div className='about-section1-6-2-2' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                            <div className='about-section1-6-2-3'>
                                <div className='about-section1-6-2-4'>상생 추구</div>
                                <div className='about-section1-6-2-5'>해피브릿지는 다 함께 살아가는것을 좋아합니다. <br />
                                    '모두가 행복한 세상' 그것을 바라보며 나아가겠습니다.</div>
                            </div>
                        </div>
                    </div>
                    <div className='about-section1-6-2'>
                        <div className='about-section1-6-5-1' ></div>
                        <div className='about-section1-6-2-2' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                            <div className='about-section1-6-2-3'>
                                <div className='about-section1-6-2-4'>Triple S<br /><span>(Speedy, Safe, Sustainable)</span></div>
                                <div className='about-section1-6-2-5'>클라우드를 활용하여 빠르고 KT 금융 클라우드 F-Cloud로 24시간 중단 없는 금융서비스로 안전하고 관리가 쉬워 지속가능합니다.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='about-section1-7'>'경쟁력을 통해 사회에 공헌하고, <br />신뢰를 주는 기업'</div>
                <div className='about-section1-8' ref={targetRef6}>
                    <div className='about-section1-8-1' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">윤리경영</div>
                    <div className='about-section1-8-2'>
                        해피브릿지는 기업윤리 실천을 위해 각종 제도와 체계를 갖추는 데 최우선으로
                        노력하고 있으며, 동시에 기업이 경제적, 법적 책임 수행 및 윤리적 책임까지도
                        기본 의무로 인정하고, 주체적인 자세로 기업윤리를 준수하고 있습니다.<br /><br />

                        기업 내에 잘못된 관행이나 비용 구조를 바로잡아 경쟁력을 향상시키고 궁극적으로
                        경쟁력을 통해 사회에 공헌하는데 목적이 있습니다.<br /><br />

                        해피브릿지 내부 뿐만 아니라 모든 이해관계자들에게 신뢰를 주는 기업이 되기 위해
                        윤리경영을 적극 실천하겠습니다.
                    </div>
                </div>
            </div>
            <div className='about-section2'>
                <div className='about-section1-1' ref={targetRef2}>
                    <div className={'about-section1-1-2'} onClick={() => onContent1Click()}>해피브릿지</div>
                    <div className={'about-section1-1-2 active'} onClick={() => onContent2Click()}>히스토리</div>
                    <div className={'about-section1-1-2'} onClick={() => onContent3Click()}>특허</div>
                </div>
                <div className='about-section1-2'></div>
                <div className='about-section2-1'>
                    <div className='about-section2-2' data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" style={{ height: "500px" }}>
                        <div className='about-section2-2-1'>
                            <div className='about-section2-2-2' style={{ height: "500px" }}></div>
                            <div className='about-section2-2-3'></div>
                            <div className='about-section2-2-4'>2024's</div>
                        </div>
                        <div className='about-section2-3'>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>11월</div>
                                <div className='about-section2-3-1-2'>qGame 계약 - Say-Pot</div>
                            </div>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>09월</div>
                                <div className='about-section2-3-1-2'>qGame - Telegram mini app 출시</div>
                            </div>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>07월</div>
                                <div className='about-section2-3-1-2'>
                                    qGame - Telegram mini app<br /><br />
                                    Happing Care
                                </div>
                            </div>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>05월</div>
                                <div className='about-section2-3-1-2'>MainNet -qChain 수출 계약(인도네시아)</div>
                            </div>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>04월</div>
                                <div className='about-section2-3-1-2'>NFT Market - qMarket 출시</div>
                            </div>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>03월</div>
                                <div className='about-section2-3-1-2'>MainNet -qChain 출시</div>
                            </div>
                        </div>
                    </div>
                    <div className='about-section2-2' data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" style={{ height: "400px" }}>
                        <div className='about-section2-2-1'>
                            <div className='about-section2-2-2' style={{ height: "400px" }}></div>
                            <div className='about-section2-2-3'></div>
                            <div className='about-section2-2-4'>2023's</div>
                        </div>
                        <div className='about-section2-3'>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>10월</div>
                                <div className='about-section2-3-1-2'>거래소(goEx) 수출 계약(파나마, 일본)</div>
                            </div>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>07월</div>
                                <div className='about-section2-3-1-2'>PG사 인프라 및 솔루션 SaaS 서비스 계약 (엠에이치퓨처스)</div>
                            </div>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>05월</div>
                                <div className='about-section2-3-1-2'>익스체인지 블루(바이낸스 브로커리지 거래소) 출시</div>
                            </div>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>04월</div>
                                <div className='about-section2-3-1-2'>PG사 인프라 및 솔루션 SaaS 서비스 계약 (앤트페이먼츠/ 케이페이먼츠)</div>
                            </div>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>03월</div>
                                <div className='about-section2-3-1-2'>KT클라우드 총판 파트너 등록</div>
                            </div>
                        </div>
                    </div>
                    <div className='about-section2-2' data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" style={{ height: "300px" }}>
                        <div className='about-section2-2-1'>
                            <div className='about-section2-2-2' style={{ height: "300px" }}></div>
                            <div className='about-section2-2-3'></div>
                            <div className='about-section2-2-4'>2022's</div>
                        </div>
                        <div className='about-section2-3'>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>12월</div>
                                <div className='about-section2-3-1-2'>페이블루 매니지드 서비스 출시</div>
                            </div>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>02월</div>
                                <div className='about-section2-3-1-2'>양자암호통신 기반의 큐월렛(Q-Wallet) 출시</div>
                            </div>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>01월</div>
                                <div className='about-section2-3-1-2'>시큐리티 블루 서비스 출시</div>
                            </div>
                        </div>
                    </div>
                    <div className='about-section2-2' data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" style={{ height: "350px" }}>
                        <div className='about-section2-2-1'>
                            <div className='about-section2-2-2' style={{ height: "350px" }}></div>
                            <div className='about-section2-2-3'></div>
                            <div className='about-section2-2-4'>2021's</div>
                        </div>
                        <div className='about-section2-3'>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>06월</div>
                                <div className='about-section2-3-1-2'>페이블루 솔루션 출시</div>
                            </div>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>05월</div>
                                <div className='about-section2-3-1-2'>
                                    KT금융클라우드 기반 금감원 클라우드 사용보고 완료<br /><br />
                                    KT금융클라우드 기반 전자지급결제대행업 신고
                                </div>
                            </div>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>04월</div>
                                <div className='about-section2-3-1-2'>"양자암호통신 기반(인프라) 시범구축"사업 참여 - KT컨소시엄</div>
                            </div>
                        </div>
                    </div>
                    <div className='about-section2-2' data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" style={{ height: "200px" }}>
                        <div className='about-section2-2-1'>
                            <div className='about-section2-2-2' style={{ height: "200px" }}></div>
                            <div className='about-section2-2-3'></div>
                            <div className='about-section2-2-4'>2020's</div>
                        </div>
                        <div className='about-section2-3'>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>12월</div>
                                <div className='about-section2-3-1-2'>스마트메일러 4.0 솔루션 출시</div>
                            </div>
                        </div>
                    </div>
                    <div className='about-section2-2' data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" style={{ height: "200px" }}>
                        <div className='about-section2-2-1'>
                            <div className='about-section2-2-2' style={{ height: "200px" }}></div>
                            <div className='about-section2-2-3'></div>
                            <div className='about-section2-2-4'>2017's</div>
                        </div>
                        <div className='about-section2-3'>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>02월</div>
                                <div className='about-section2-3-1-2'>한국산업개발연구원창업지원앱센터 오픈</div>
                            </div>
                        </div>
                    </div>
                    <div className='about-section2-2' data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" style={{ height: '200px' }}>
                        <div className='about-section2-2-1'>
                            <div className='about-section2-2-2' style={{ height: "200px" }}></div>
                            <div className='about-section2-2-3'></div>
                            <div className='about-section2-2-4'>2016's</div>
                        </div>
                        <div className='about-section2-3'>
                            <div className='about-section2-3-1'>
                                <div className='about-section2-3-1-1'>11월</div>
                                <div className='about-section2-3-1-2'>해피브릿지 설립</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='about-section3'>
                <div className='about-section1-1' ref={targetRef3}>
                    <div className={'about-section1-1-2'} onClick={() => onContent1Click()}>해피브릿지</div>
                    <div className={'about-section1-1-2'} onClick={() => onContent2Click()}>히스토리</div>
                    <div className={'about-section1-1-2 active'} onClick={() => onContent3Click()}>특허</div>
                </div>
                <div className='about-section1-2'></div>
                <div className='about-section3-1'>
                    <div className='about-section3-1-1' >
                        <div className='about-section3-1-2'>Trademark<span>상표등록증</span></div>
                        <div className='about-sectiion3-1-3' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                            <img src='/img/patent/상표등록증_5건_KIDn_등_1.jpg' />
                            <img src='/img/patent/상표등록증_5건_KIDn_등_2.jpg' />
                            <img src='/img/patent/상표등록증_5건_KIDn_등_3.jpg' />
                            <img src='/img/patent/상표등록증_5건_KIDn_등_4.jpg' />
                            <img src='/img/patent/상표등록증_5건_KIDn_등_5.jpg' />
                        </div>
                    </div>
                    <div className='about-section3-1-1' >
                        <div className='about-section3-1-2'>System<span>시스템 및 기술 관련 특허</span></div>
                        <div className='about-sectiion3-1-3' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                            <img src='/img/patent/전자금융업_등록_완료_통보(주식회사_해피브릿지)_1.jpg' />
                            <img src='/img/patent/특허증_QR코드_또는_스마트_태그(온라인).jpg' />
                            <img src='/img/patent/특허증_맞춤형_봉사료_1.jpg' />
                            <img src='/img/patent/특허증_QR코드_또는_스마트_태그_1.jpg' />
                            <img src='/img/patent/특허증_스팸메일_차단방법_및_시스템(소멸)_1.jpg' />
                            <img src='/img/patent/특허증_2건_IoT_1.jpg' />
                            <img src='/img/patent/특허증_2건_IoT_3.jpg' />
                            <img src='/img/patent/특허증_사용환경동기화_1.jpg' />
                            <img src='/img/patent/특허증_자동제어기능_플러그_1.jpg' />
                            <img src='/img/patent/특허증_전기기기_관리장치_1.jpg' />
                            <img src='/img/patent/특허증_주소록_관리_시스템_1.jpg' />
                        </div>
                    </div>
                    <div className='about-section3-1-1' >
                        <div className='about-section3-1-2'>Etc<span>기타</span></div>
                        <div className='about-sectiion3-1-3' data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                            <img src='/img/patent/특허증_2건_화장품용기_1.jpg' />
                            <img src='/img/patent/특허증_2건_화장품용기_2.jpg' />
                            <img src='/img/patent/특허증_모의_총기_시스템_1.jpg' />
                            <img src='/img/patent/특허증_액션피규어_1.jpg' />
                            <img src='/img/patent/특허증_유아용_바운서_1.jpg' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;